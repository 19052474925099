//
// Component: Card
//
// ========================================================================


// Variables
// ========================================================================





//
// New
//









// Component
// ========================================================================




// Sections
// ========================================================================

// @mixin hook-card-body(){}

// @mixin hook-card-header(){}

// @mixin hook-card-footer(){}


// Media
// ========================================================================

// @mixin hook-card-media(){}

// @mixin hook-card-media-top(){}

// @mixin hook-card-media-bottom(){}

// @mixin hook-card-media-left(){}

// @mixin hook-card-media-right(){}


// Title
// ========================================================================

// @mixin hook-card-title(){}


// Badge
// ========================================================================




// Hover modifier
// ========================================================================




// Style modifiers
// ========================================================================



// @mixin hook-card-default-title(){}







//
// Primary
//



// @mixin hook-card-primary-title(){}



//
// Secondary
//



// @mixin hook-card-secondary-title(){}




// Miscellaneous
// ========================================================================


