//
// Component: Button
//
// ========================================================================


// Variables
// ========================================================================






//
// New
//







// Component
// ========================================================================



// @mixin hook-button-hover(){}

// @mixin hook-button-active(){}


// Style modifiers
// ========================================================================







//
// Primary
//



// @mixin hook-button-primary-hover(){}

// @mixin hook-button-primary-active(){}

//
// Secondary
//



// @mixin hook-button-secondary-hover(){}

// @mixin hook-button-secondary-active(){}

//
// Danger
//



// @mixin hook-button-danger-hover(){}

// @mixin hook-button-danger-active(){}


// Disabled
// ========================================================================




// Size modifiers
// ========================================================================

// @mixin hook-button-small(){}

// @mixin hook-button-large(){}


// Text modifier
// ========================================================================








// Link modifier
// ========================================================================

// @mixin hook-button-link(){}


// Miscellaneous
// ========================================================================




// Inverse
// ========================================================================







// @mixin hook-inverse-button-primary(){}
// @mixin hook-inverse-button-primary-hover(){}
// @mixin hook-inverse-button-primary-active(){}

// @mixin hook-inverse-button-secondary(){}
// @mixin hook-inverse-button-secondary-hover(){}
// @mixin hook-inverse-button-secondary-active(){}


// @mixin hook-inverse-button-text-hover(){}
// @mixin hook-inverse-button-text-disabled(){}

// @mixin hook-inverse-button-link(){}
