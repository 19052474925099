//
// Component: Tile
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-tile(){}


// Style modifiers
// ========================================================================

// @mixin hook-tile-default(){}

// @mixin hook-tile-default-hover(){}

//
// Muted
//

// @mixin hook-tile-muted(){}

// @mixin hook-tile-muted-hover(){}

//
// Primary
//

// @mixin hook-tile-primary(){}

// @mixin hook-tile-primary-hover(){}

//
// Secondary
//

// @mixin hook-tile-secondary(){}

// @mixin hook-tile-secondary-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-tile-misc(){}
