//
// Component: Variables
//
// ========================================================================


// Global variables
// ========================================================================

//
// Typography
//

//
// Colors
//

//
// Backgrounds
//

//
// Borders
//

//
// Spacings
//

//
// Controls
//

//
// Z-index
//
