//
// Component: Alert
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//



// Component
// ========================================================================

// @mixin hook-alert(){}


// Close
// ========================================================================






// Style modifiers
// ========================================================================

// @mixin hook-alert-primary(){}

// @mixin hook-alert-success(){}

// @mixin hook-alert-warning(){}

// @mixin hook-alert-danger(){}


// Miscellaneous
// ========================================================================


