//
// Component: Modal
//
// ========================================================================


// Variables
// ========================================================================


//
// New
//






// Component
// ========================================================================

// @mixin hook-modal(){}


// Dialog
// ========================================================================

// @mixin hook-modal-dialog(){}


// Full
// ========================================================================

// @mixin hook-modal-full(){}


// Sections
// ========================================================================



// @mixin hook-modal-body(){}




// Title
// ========================================================================

// @mixin hook-modal-title(){}


// Close
// ========================================================================

// @mixin hook-modal-close(){}

// @mixin hook-modal-close-hover(){}

// @mixin hook-modal-close-default(){}

// @mixin hook-modal-close-default-hover(){}

// @mixin hook-modal-close-outside(){}

// @mixin hook-modal-close-outside-hover(){}



// @mixin hook-modal-close-full-hover(){}


// Miscellaneous
// ========================================================================


