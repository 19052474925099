//
// Component: Label
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//



// Component
// ========================================================================




// Color modifiers
// ========================================================================

// @mixin hook-label-success(){}

// @mixin hook-label-warning(){}

// @mixin hook-label-danger(){}


// Miscellaneous
// ========================================================================

// @mixin hook-label-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-label(){}
