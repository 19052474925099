//
// Component:       Variables
// Description:     Defines common values which are used across all components
//
// ========================================================================


// Load deprecated components
// ========================================================================



// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

$breakpoint-small:                              640px !default;  // Phone landscape
$breakpoint-medium:                             960px !default;  // Tablet Landscape
$breakpoint-large:                              1200px !default; // Desktop
$breakpoint-xlarge:                             1600px !default; // Large Screens



// Global variables
// ========================================================================

//
// Typography
//

$global-line-height:                             1.5 !default;      // 24px

$global-2xlarge-font-size:                       2.625rem !default; // 42px
$global-xlarge-font-size:                        2rem !default;     // 32px
$global-large-font-size:                         1.5rem !default;   // 24px
$global-medium-font-size:                        1.25rem !default;  // 20px
$global-small-font-size:                         0.875rem !default; // 14px

//
// Colors
//




//
// Backgrounds
//




//
// Borders
//


//
// Box-Shadows
//


//
// Spacings
//

// Used in margin, section, list

// Used in grid, column, container, align, card, padding

//
// Controls
//


//
// Z-index
//

