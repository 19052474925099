//
// Component: Description list
//
// ========================================================================


// Variables
// ========================================================================



// Component
// ========================================================================



// @mixin hook-description-list-description(){}


// Style modifier
// ========================================================================

// @mixin hook-description-list-divider-term(){}


// Miscellaneous
// ========================================================================

// @mixin hook-description-list-misc(){}
