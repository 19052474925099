//
// Component: Tab
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//





// Component
// ========================================================================




// Items
// ========================================================================



// @mixin hook-tab-item-hover(){}



// @mixin hook-tab-item-disabled(){}


// Position modifiers
// ========================================================================














// Miscellaneous
// ========================================================================




// Inverse
// ========================================================================




// @mixin hook-inverse-tab-item(){}
// @mixin hook-inverse-tab-item-hover(){}

// @mixin hook-inverse-tab-item-disabled(){}
