//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//






// Body
// ========================================================================

// @mixin hook-base-body(){}


// Links
// ========================================================================

// @mixin hook-base-link(){}

// @mixin hook-base-link-hover(){}


// Text-level semantics
// ========================================================================




// Headings
// ========================================================================

// @mixin hook-base-heading(){}

// @mixin hook-base-h1(){}

// @mixin hook-base-h2(){}

// @mixin hook-base-h3(){}

// @mixin hook-base-h4(){}

// @mixin hook-base-h5(){}

// @mixin hook-base-h6(){}


// Horizontal rules
// ========================================================================

// @mixin hook-base-hr(){}


// Blockquotes
// ========================================================================






// Preformatted text
// ========================================================================




// Miscellaneous
// ========================================================================

// @mixin hook-base-misc(){}


// Inverse
// ========================================================================


// @mixin hook-inverse-base-link(){}
// @mixin hook-inverse-base-link-hover(){}



// @mixin hook-inverse-base-heading(){}

// @mixin hook-inverse-base-h1(){}
// @mixin hook-inverse-base-h2(){}
// @mixin hook-inverse-base-h3(){}
// @mixin hook-inverse-base-h4(){}
// @mixin hook-inverse-base-h5(){}
// @mixin hook-inverse-base-h6(){}




// @mixin hook-inverse-base-hr(){}
