//
// Component: Article
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//



// Component
// ========================================================================

// @mixin hook-article(){}


// Adjacent sibling
// ========================================================================

// @mixin hook-article-adjacent(){}


// Title
// ========================================================================

// @mixin hook-article-title(){}


// Meta
// ========================================================================




// Miscellaneous
// ========================================================================

// @mixin hook-article-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-article-meta(){}
