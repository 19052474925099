//
// Component: Navbar
//
// ========================================================================


// Variables
// ========================================================================








//
// New
//






// Component
// ========================================================================

// @mixin hook-navbar(){}


// Container
// ========================================================================

// @mixin hook-navbar-container(){}


// Nav
// ========================================================================



// @mixin hook-navbar-nav-item-hover(){}

// @mixin hook-navbar-nav-item-onclick(){}

// @mixin hook-navbar-nav-item-active(){}


// Item
// ========================================================================

// @mixin hook-navbar-item(){}


// Toggle
// ========================================================================

// @mixin hook-navbar-toggle(){}

// @mixin hook-navbar-toggle-hover(){}

// @mixin hook-navbar-toggle-icon(){}

// @mixin hook-navbar-toggle-icon-hover(){}


// Subtitle
// ========================================================================

// @mixin hook-navbar-subtitle(){}


// Style modifiers
// ========================================================================

// @mixin hook-navbar-primary(){}

// @mixin hook-navbar-transparent(){}

// @mixin hook-navbar-sticky(){}


// Dropdown
// ========================================================================



// @mixin hook-navbar-dropdown-large(){}



// @mixin hook-navbar-dropdown-dropbar-large(){}


// Dropdown nav
// ========================================================================



// @mixin hook-navbar-dropdown-nav-item(){}

// @mixin hook-navbar-dropdown-nav-item-hover(){}

// @mixin hook-navbar-dropdown-nav-subtitle(){}

// @mixin hook-navbar-dropdown-nav-header(){}

// @mixin hook-navbar-dropdown-nav-divider(){}


// Dropbar
// ========================================================================

// @mixin hook-navbar-dropbar(){}


// Miscellaneous
// ========================================================================




// Inverse
// ========================================================================

// @mixin hook-inverse-navbar-nav-item(){}
// @mixin hook-inverse-navbar-nav-item-hover(){}
// @mixin hook-inverse-navbar-nav-item-onclick(){}
// @mixin hook-inverse-navbar-nav-item-active(){}

// @mixin hook-inverse-navbar-item(){}

// @mixin hook-inverse-navbar-toggle(){}
// @mixin hook-inverse-navbar-toggle-hover(){}
