//
// Component: Search
//
// ========================================================================


// Variables
// ========================================================================



//
// New
//






// Component
// ========================================================================

// @mixin hook-search-input(){}


// Icon
// ========================================================================

// @mixin hook-search-icon(){}


// Default modifiers
// ========================================================================






// Navbar modifiers
// ========================================================================






// Medium modifiers
// ========================================================================

// @mixin hook-search-medium-input(){}

// @mixin hook-search-medium-input-focus(){}


// Large modifiers
// ========================================================================

// @mixin hook-search-large-input(){}

// @mixin hook-search-large-input-focus(){}


// Toggle
// ========================================================================

// @mixin hook-search-toggle(){}

// @mixin hook-search-toggle-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-search-misc(){}


// Inverse
// ========================================================================




// @mixin hook-inverse-search-default-input-focus(){}


// @mixin hook-inverse-search-navbar-input-focus(){}

// @mixin hook-inverse-search-medium-input(){}
// @mixin hook-inverse-search-medium-input-focus(){}

// @mixin hook-inverse-search-large-input(){}
// @mixin hook-inverse-search-large-input-focus(){}

// @mixin hook-inverse-search-toggle(){}
// @mixin hook-inverse-search-toggle-hover(){}
