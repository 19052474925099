//
// Component: Badge
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-badge(){}

// @mixin hook-badge-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-badge-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-badge(){}
// @mixin hook-inverse-badge-hover(){}
